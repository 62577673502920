.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  color: white;

  display: flex;
  justify-content: space-between;
  padding: 20px 20px;

  .img {
    height: 5rem;
  }
}
